import { Turbo } from '@hotwired/turbo-rails'
import { init as initSentry } from '@/packs/sentry'
import { metaTag } from '@/utils/metatag'

initSentry(metaTag('sentry_dsn'))

import '@/controllers'

import.meta.glob('../channels/**/*_channel.js', { eager: true })
import.meta.glob('../validators/**/*', { eager: true })

// ===== Turbo settings =====
// Progress bar
Turbo.setProgressBarDelay(200)

// Confirmation modal
Turbo.setConfirmMethod(() => {
	const dialog = document.getElementById('confirmation-modal')
	dialog.showModal()

	return new Promise((resolve, _) => {
		dialog.addEventListener('close', () => {
			resolve(dialog.returnValue === 'confirm')
		}, { once: true })
	})
})

// Temporary Safari NSURL hotfix: https://docs.stimulusreflex.com/appendices/troubleshooting.html#safari-nsurlsession-websocket-bug
const originalWebSocketClose = WebSocket.prototype.close
WebSocket.prototype.close = function () {
	if (this.readyState != WebSocket.CONNECTING) {
		originalWebSocketClose.apply(this, arguments)
	}
}

