import { Application } from '@hotwired/stimulus'
import applicationController from './application_controller'
import StimulusControllerResolver, { createViteGlobResolver } from 'stimulus-controller-resolver'
import StimulusReflex from 'stimulus_reflex'
import consumer from '@/channels/consumer'


const application = Application.start()
application.consumer = consumer
window.Stimulus = application

// Load only controllers that are specified in the DOM
StimulusControllerResolver.install(application, createViteGlobResolver(
	import.meta.glob('../controllers/**/*_controller.js'),
	import.meta.glob('../components/**/*_controller.js'),
))

StimulusReflex.initialize(application, { applicationController, isolate: true })

// if (import.meta.env.NODE_ENV === 'development') {
// 	StimulusReflex.debug = true
// 	application.debug = true
// }

export { application }