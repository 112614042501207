import * as Sentry from '@sentry/browser'

export function init(dsn) {
	if (typeof dsn != 'undefined') {
		Sentry.init({
			dsn: dsn,
			integrations: [new Sentry.BrowserTracing(), new Sentry.Replay(
				{
					maskAllInputs: true,
				})],
			tracesSampleRate: 0.2,
			replaysSessionSampleRate: 0.2,
			replaysOnErrorSampleRate: 1.0,
		})
	}
}