import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
	reflex() {
		StimulusReflex.register(this)
	}

	_renderBanner(type, message) {
		this.stimulate('Notifications#render_flash_banner', {
			type: type,
			message: message,
		})
	}
}
